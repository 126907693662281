import React, {useState, useEffect} from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import navIcon from '../../../images/icons/icon-compare-nav.svg'
import useMobileHook from '../../../utils/use-mobile-hook'

const MegaMenuStyles = styled.div`
  .mega-menu {
    .inner-3-col {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding: 20px 20px;
      @media (min-width: 1170px) {
        padding-top: 40px;
      }
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      justify-items: center;
      a {
        padding: 0;
        line-height: 0;
        position: relative;
        overflow: hidden;
        .prod-label {
          transition: all 300ms ease-in-out;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 9;
          width: 100%;
          height: 50%;
          background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(32, 32, 32, 0.51) 100%
          );
          text-align: center;
          padding: 80px 20px 20px 20px;
          // transform: translateY(100%);
          transform: translateY(0);
          &:after {
            content: url(${props => props.navIcon});
            position: relative;
            top: 4px;
            left: 10px;
          }
        }
        // &:hover {
        //   .prod-label {
        //     transform: translateY(0);
        //   }
        // }
      }
      .compare-row {
        grid-column: 1 / span 3;
        padding: 10px 0 !important;
        align-self: flex-end;
        a {
          background: rgba(0, 0, 0, 0.1);
          padding: 20px 60px 20px 40px;
          line-height: 16px;
          border-radius: 30px;
          transition: all 300ms ease-in-out;
          position: relative;
          &:hover {
            background: rgba(0, 0, 0, 0.2);
          }
          &:after {
            content: url(${props => props.navIcon});
            position: absolute;
            right: 23px;
            top: 18px;
          }
        }
        padding: 0 0 30px 0;
      }
    }
    a {
      border: none;
      &:hover {
        border: none;
        background: none;
      }
    }
    @media (max-width: 900px) {
      .inner-3-col {
        display: block;
        padding: 0;
        img {
          display: none;
        }
        a {
          line-height: 19px;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          text-transform: capitalize;
          font-size: 16px;
          .prod-label {
            position: relative;
            transform: translateY(0);
            top: 0;
            padding: 0;
            height: 100%;
            background-image: none;
            text-align: left;
            &:after {
              content: '';
            }
          }
          .gatsby-image-wrapper {
            display: none !important;
          }
        }
        .compare-row {
          padding: 0 !important;
          a {
            background: none;
            border: none;
            padding: 15px 25px;
            line-height: 20px;
            border-radius: 0;
            &:after {
              content: '';
            }
          }
        }
      }
    }
  }
`

const MegaMenuMattresses = ({mobileMenuVisible, mobileMenuCloseHandler}) => {
  const isMobile = useMobileHook({maxWidth: 900})

  const data = useStaticQuery(graphql`
    {
      bodyCharger: file(relativePath: {eq: "kooi-body-charger-inner.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      neroBoost: file(relativePath: {eq: "kooi-nero-boost-inner.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      bodyTrainer: file(relativePath: {eq: "kooi-body-trainer-inner.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <MegaMenuStyles navIcon={navIcon}>
      <span className="mega-menu">
        <div className="inner-3-col">
          <Link
            to="/product/kooi-body-charger-mattress/"
            onClick={mobileMenuCloseHandler}
          >
            <div className="prod-label">Kooi Body Charger</div>
            {!isMobile && (
              <Img
                fixed={data.bodyCharger.childImageSharp.fixed}
                alt="Kooi Body Charger"
              />
            )}
          </Link>
          <Link
            to="/product/kooi-nero-boost-mattress/"
            onClick={mobileMenuCloseHandler}
          >
            <div className="prod-label">Kooi Nero Boost</div>
            {!isMobile && (
              <Img
                fixed={data.neroBoost.childImageSharp.fixed}
                alt="Kooi Nero Boost"
              />
            )}
          </Link>
          <Link
            to="/product/kooi-body-trainer-mattress/"
            onClick={mobileMenuCloseHandler}
          >
            <div className="prod-label">Kooi Body Trainer</div>
            {!isMobile && (
              <Img
                fixed={data.bodyTrainer.childImageSharp.fixed}
                alt="Kooi Body Trainer"
              />
            )}
          </Link>
          <div className="compare-row">
            <Link
              to="/compare-our-active-mattresses/"
              onClick={mobileMenuCloseHandler}
            >
              Compare Active Range
            </Link>
            <Link
              to="/compare-our-b-series-mattresses/"
              onClick={mobileMenuCloseHandler}
            >
              Compare B-Series Range
            </Link>
            <Link
              to="/compare-our-premium-series-mattresses/"
              onClick={mobileMenuCloseHandler}
            >
              Compare Premium Range
            </Link>
          </div>
        </div>
      </span>
    </MegaMenuStyles>
  )
}

export default MegaMenuMattresses
