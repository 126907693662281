export const config = {
  wordPressUrl: `${process.env.GATSBY_WC_BLOG_HOST}/`,
}

export const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(config.wordPressUrl, ``)
}

// get first paragraph content
export const getFirstParagraph = text => {
  if (text.includes('<p>') && text.includes('</p>')) {
    const from = text.indexOf('<p>') + 3
    const upto = text.indexOf('</p>')
    return text.slice(from, upto)
  }
  return text
}

// strip  away last paragraph
export const stripLastParagraph = text => {
  if (text.includes('<p>')) {
    const upto = text.lastIndexOf('<p>')
    return text.slice(0, upto)
  }
  return text
}

export const scrollToTop = () => {
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    // Scroll to top on each render
    try {
      // try to new API (https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      // legacy for older browsers
      window.scrollTo(0, 0)
    }
  }
}
export const handleScrollIntoView = elementId => {
  try {
    const isBrowser = () => typeof window !== 'undefined'
    if (isBrowser()) {
      setTimeout(() => {
        const element = document.getElementById(elementId || '___gatsby')
        if (element) element.scrollIntoView({behavior: 'smooth'})
      }, 100)
    }
  } catch (e) {}
}

// Helper function to check if on "mobile"
export const isOnMobile = () => {
  let screenWidth = 1000
  if (typeof window !== 'undefined') {
    screenWidth =
      (window && window.screen && window.screen.width) || screenWidth
  }
  return screenWidth <= 768 ? true : false
}

// FORMAT CURRENCY IN ZAR -----------------------------------------------------
export const formatMoney = amount => {
  const options = {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2,
  }
  // if its a whole, dollar amount, leave off the .00
  if (amount % 100 === 0) options.minimumFractionDigits = 2
  const formatter = new Intl.NumberFormat('en-US', options)
  const longFormat = formatter.format(amount)

  return longFormat.replace('ZAR', 'R')
}

// Parse URL query values -----------------------------------------------------
export const getQueryVariable = (variable, optCallback) => {
  if (typeof window !== 'undefined') {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] == variable) {
        if (optCallback) optCallback(pair[1])
        return pair[1]
      }
    }
  }
  if (optCallback) optCallback(false)
  return false
}

// Map Date.getMonth() integer to a month name
export const mapMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// Add a zero for single digits (for displaying date)
export const formatTime = i => {
  let hours = i.getHours()
  // add leading zero to minutes if less than 10
  const minutes = (i.getMinutes() < 10 ? '0' : '') + i.getMinutes()

  // it is pm if hours from 12 onwards
  const suffix = hours >= 12 ? 'pm' : 'am'

  // only -12 from hours if it is greater than 12 (if not back at mid night)
  hours = hours > 12 ? hours - 12 : hours

  // if 00 then it is 12 am
  hours = hours === '00' ? 12 : hours

  return `${hours}:${minutes} ${suffix}`
}

// Create nice slugs from complex strings
export const slugify = string => {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const decodeHtmlEncodedString = function(string) {
  return (string + '').replace(/&#\d+;/gm, function(s) {
    return String.fromCharCode(s.match(/\d+/gm)[0])
  })
}

// get url for tje specified country
export const getSpecifiedCountryUrl = (pathname, country) => {
  let newPath = ''
  let retUrl = ''
  const redirectToHome = ['/about/reviews/', '/about/philanthropy/']
  if (!redirectToHome.includes(pathname)) {
    newPath = pathname
    switch (country.toLowerCase()) {
      case 'za': {
        retUrl = `${process.env.GATSBY_WC_DOMAIN_ZA}${newPath}`
        break
      }
      case 'nz': {
        retUrl = `${process.env.GATSBY_WC_DOMAIN_NZ}${newPath}`
        break
      }
      default: {
        console.log('Error: Invalid country requested. country = ', country)
        break
      }
    }
  }
  return retUrl
}
