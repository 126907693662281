import React, {useState} from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const MegaMenuStyles = styled.div`
  .mega-menu {
    text-transform: capitalize;
    .inner-2-col {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding: 40px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    .img {
      text-align: left;
    }
    a {
      text-transform: capitalize;
      display: block;
      border: none;
      padding: 15px 20px;
      text-align: right;
      transition: all 300ms ease-in-out;
      opacity: 1;
      &:hover {
        background: none;
        border: none;
      }
    }
    .pillow .pillow:after,
    .protector .protector:after,
    .topper .topper:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid ${props => props.theme.red};
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      top: calc(50% - 10px);
      right: -12px;
    }
    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
        object-position: center bottom !important;
        max-width: 100% !important;
      }
    }
    @media (max-width: 900px) {
      .inner-2-col {
        display: block;
        padding: 0;
        .img {
          display: none;
        }
        a {
          text-align: left;
          opacity: 1;
          line-height: 19px;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          text-transform: capitalize;
          font-size: 16px;
        }
      }
    }
  }
`

const MegaMenuAccessories = ({mobileMenuVisible, mobileMenuCloseHandler}) => {
  const data = useStaticQuery(graphql`
    {
      charcoalpillow: file(
        relativePath: {eq: "kooi-dual-charcoal-pillow.jpg"}
      ) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      charcoallatexpillow: file(
        relativePath: {eq: "kooi-latex-charcoal-pillow.jpg"}
      ) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      charcoalmemorypillow: file(
        relativePath: {eq: "kooi-memory-charcoal-pillow.jpg"}
      ) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      monitor: file(relativePath: {eq: "nav-image-sleep-monitor.jpg"}) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      protector: file(relativePath: {eq: "nav-image-mattress-protector.jpg"}) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      petsmattress: file(relativePath: {eq: "kooi-pet-mattress.jpg"}) {
        childImageSharp {
          fixed(height: 240, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const [img, setImg] = useState(data.charcoalpillow)
  const [title, setTitle] = useState('Pillows')
  const [link, setLink] = useState('/product/natural-latex-pillow/')

  const handleChange = (imgData, title, link) => {
    setImg(imgData)
    setTitle(title)
    setLink(link)
  }

  return (
    <MegaMenuStyles>
      <span className="mega-menu">
        <div className="inner-2-col">
          <div className={`col-left ${img}`}>
            <Link
              to="/product/kooi-dual-charcoal-pillow/"
              onMouseOver={() =>
                handleChange(
                  data.charcoalpillow,
                  'Kooi Dual Charcoal Pillow',
                  '/product/kooi-dual-charcoal-pillow/',
                )
              }
              onFocus={() =>
                handleChange(
                  data.charcoalpillow,
                  'Kooi Dual Charcoal Pillow',
                  '/product/kooi-dual-charcoal-pillow/',
                )
              }
              onClick={mobileMenuCloseHandler}
              className="pillow"
            >
              Kooi Dual Charcoal Pillow
            </Link>

            <Link
              to="/product/kooi-latex-charcoal-pillow/"
              onMouseOver={() =>
                handleChange(
                  data.charcoallatexpillow,
                  'Kooi Latex Charcoal Pillow',
                  '/product/kooi-latex-charcoal-pillow/',
                )
              }
              onFocus={() =>
                handleChange(
                  data.charcoallatexpillow,
                  'Kooi Latex Charcoal Pillow',
                  '/product/kooi-latex-charcoal-pillow/',
                )
              }
              onClick={mobileMenuCloseHandler}
              className="pillow"
            >
              Kooi Latex Charcoal Pillow
            </Link>

            <Link
              to="/product/kooi-memory-charcoal-pillow/"
              onMouseOver={() =>
                handleChange(
                  data.charcoalmemorypillow,
                  'Kooi Memory Charcoal Pillow',
                  '/product/kooi-memory-charcoal-pillow/',
                )
              }
              onFocus={() =>
                handleChange(
                  data.charcoalmemorypillow,
                  'Kooi Memory Charcoal Pillow',
                  '/product/kooi-memory-charcoal-pillow/',
                )
              }
              onClick={mobileMenuCloseHandler}
              className="pillow"
            >
              Kooi Memory Charcoal Pillow
            </Link>

            {/*<Link*/}
            {/*  to="/product/mattress-protector/"*/}
            {/*  onMouseOver={() =>*/}
            {/*    handleChange(*/}
            {/*      data.protector,*/}
            {/*      'Mattress Protector',*/}
            {/*      '/product/mattress-protector/',*/}
            {/*    )*/}
            {/*  }*/}
            {/*  onFocus={() =>*/}
            {/*    handleChange(*/}
            {/*      data.protector,*/}
            {/*      'Mattress Protector',*/}
            {/*      '/product/mattress-protector/',*/}
            {/*    )*/}
            {/*  }*/}
            {/*  onClick={mobileMenuCloseHandler}*/}
            {/*  className="protector"*/}
            {/*>*/}
            {/*  Mattress Protector*/}
            {/*</Link>*/}

            <Link
              to="/product/kooi-pets-mattress/"
              onMouseOver={() =>
                handleChange(
                  data.petsmattress,
                  'Kooi Pets Mattress',
                  '/product/kooi-pets-mattress/',
                )
              }
              onFocus={() =>
                handleChange(
                  data.petsmattress,
                  'Kooi Pets Mattress',
                  '/product/kooi-pets-mattress/',
                )
              }
              onClick={mobileMenuCloseHandler}
              className="protector"
            >
              Kooi Pets Mattress
            </Link>
          </div>
          <div className="col-right">
            <Link to={link} className="img">
              <Img fixed={img.childImageSharp.fixed} alt={title} />
            </Link>
          </div>
        </div>
      </span>
    </MegaMenuStyles>
  )
}

export default MegaMenuAccessories
