import React, {useState, useEffect} from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import navIcon from '../../../images/icons/icon-compare-nav.svg'
import useMobileHook from '../../../utils/use-mobile-hook'

const MegaMenuStyles = styled.div`
  .mega-menu {
    .inner-2-col {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding: 20px 20px 40px 20px;
      @media (min-width: 1170px) {
        padding-top: 40px;
      }
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      .col-1 {
        justify-self: flex-end;
      }
      .col-2 {
        justify-self: flex-start !important;
      }
      a {
        padding: 0;
        line-height: 0;
        position: relative;
        overflow: hidden;
        .prod-label {
          transition: all 300ms ease-in-out;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 9;
          width: 100%;
          height: 50%;
          background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(32, 32, 32, 0.51) 100%
          );
          text-align: center;
          padding: 80px 20px 20px 20px;
          // transform: translateY(100%);
          transform: translateY(0);
          &:after {
            content: url(${props => props.navIcon});
            position: relative;
            top: 4px;
            left: 10px;
          }
        }
        .gatsby-image-wrapper {
          max-height: 233px;
        }
        // &:hover {
        //   .prod-label {
        //     transform: translateY(0);
        //   }
        // }
      }
    }
    a {
      border: none;
      &:hover {
        border: none;
        background: none;
      }
    }
    @media (max-width: 900px) {
      .inner-2-col {
        display: block;
        padding: 0;
        img {
          display: none;
        }
        a {
          line-height: 19px;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          text-transform: capitalize;
          font-size: 16px;
          .prod-label {
            position: relative;
            transform: translateY(0);
            top: 0;
            padding: 0;
            height: 100%;
            background-image: none;
            text-align: left;
            &:after {
              content: '';
            }
          }
          .gatsby-image-wrapper {
            display: none !important;
          }
        }
      }
    }
  }
`

const MegaMenuMattresses = ({mobileMenuVisible, mobileMenuCloseHandler}) => {
  const isMobile = useMobileHook({maxWidth: 900})

  const data = useStaticQuery(graphql`
    {
      thumbWooden: file(relativePath: {eq: "nav-image-wooden-base.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      thumbPremiumBase: file(relativePath: {eq: "kooi-barcelona-base.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <MegaMenuStyles navIcon={navIcon}>
      <span className="mega-menu">
        <div className="inner-2-col">
          <Link
            to="/product/wooden-base/"
            onClick={mobileMenuCloseHandler}
            className="col-1"
          >
            <div className="prod-label">Wooden Base</div>
            {!isMobile && (
              <Img
                fixed={data.thumbWooden.childImageSharp.fixed}
                alt="Wooden Base"
              />
            )}
          </Link>
          <Link
            to="/product/kooi-premium-base/"
            onClick={mobileMenuCloseHandler}
            className="col-2"
          >
            <div className="prod-label">Kooi Premium Base</div>
            {!isMobile && (
              <Img
                fixed={data.thumbPremiumBase.childImageSharp.fixed}
                alt="Kooi Premium Base"
              />
            )}
          </Link>
        </div>
      </span>
    </MegaMenuStyles>
  )
}

export default MegaMenuMattresses
